/**
 * Header
 */

.site-header {
  background: color('weiss');

  a {
    color: color('grau');
    font-weight: bolder;
  }

  ul {
    justify-content: space-between;
  }

  li {
    padding-bottom: 0;
  }

  .burger-menu {
    font-size: 2.2rem;
  }
}

.nav-main {
  &-link {
    color: color('grau');
  }
}

// Logo size

.menu-content {
  justify-content: space-between;
}

nav {
  z-index: 10000;

  @media (min-width: breakpoint(md)) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .menu-content {
    @media (max-width: breakpoint(md)) {
      height: 4rem;
    }
    // Logo size
    .main-logo {
      width: 100%;
      height: auto;
      padding-top: 0;
      max-width: 15rem;

      @media (min-width: breakpoint(md)) {
        padding-top: 3rem;
        max-width: 21rem;
      }
    }

    .nav-drop {
      position: fixed;
      top: 9rem;
      background: color('beige');
      color: color('weiss');
      overflow: hidden;
      font-size: initial;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-flow: column;
      transition: all 1s ease;
      /* stylelint-disable */
      max-height: 0;
      border-top: 1px solid color('weiss');

      a {
        text-transform: uppercase;
      }

      @media (max-width: breakpoint(md)) {
        top: 4rem;
        right: 0;
        width: 100%;
      }

      &.show {
        max-height: 40em;
      }
      /* stylelint-enable */

      li {
        padding: 1em 0;

        a {
          color: color('grau');
        }
      }
    }
  }
}

.desktop-menu {
  @media (max-width: breakpoint(md)) {
    display: none !important;
  }
}

.mobile-menu {
  @media (max-width: breakpoint(md)) {
    display: flex !important;
  }
}

.scroll-logo {
  width: 45px;
  height: 45px;
  display: none;

  @media (min-width: breakpoint(md)) {
    width: 67px;
    height: 67px;
    margin-top: 4rem;
    display: none;
  }
}

.scroll-down {
  header {
    border-bottom: 1px solid orange;
    padding: 0;
    box-shadow: 9px 10px 8px -2px rgba(176, 176, 176, 0.74);
  }

  .main-logo {
    padding-top: 0;
    display: none;
  }

  .scroll-logo {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;
    transform: translateX(150px);
    animation: scroll-stern 0.5s ease-in;
    animation-fill-mode: forwards;
  }

  .site-header li {
    padding-bottom: 0 !important;
  }

  .container {
    padding: 0;
  }

  @keyframes scroll-stern {
    0% {
      transform: rotateZ(0deg);
    }

    1% {
      transform: translateX(260px);
    }

    86% {
      transform: rotateZ(-179deg);
    }

    99% {
      transform: rotateZ(-216deg);
    }

    100% {
      transform: translateX(0);
    }
  }
}
