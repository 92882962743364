.site-footer {
  background: color('primary');
  color: color('secondary');

  a {
    line-height: 1.8 !important;
  }

  .border-right {
    border-right: 1px solid color('weiss');

    @media (max-width: breakpoint(md)) {
      border-right: 1px solid color('schwarz');
    }
  }
}
