// *
// * Include Fonts and define basic font ajustments and styling
// *

$fonts-path: '../fonts';

@font-face {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/kufam-v2-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/kufam-v2-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/kufam-v2-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/kufam-v2-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/kufam-v2-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/kufam-v2-latin-regular.svg#Kufam') format('svg'); /* Legacy iOS */
}

.font-brand {
  font-family: sans-serif;
}

.is-style-heading-size-1,
.heading-size-1 {
  @include font-size(2.5rem);

  line-height: 130%;
}

.is-style-heading-size-2,
.heading-size-2 {
  @include font-size(1.75rem);
  @media (min-width: breakpoint(lg)) {
    @include font-size(1.8rem);
  }
}

.is-style-heading-size-3,
.heading-size-3 {
  @include font-size(1.5rem);
}

.is-style-heading-size-4,
.heading-size-4 {
  @include font-size(1.3rem);
}
